import React, { FunctionComponent, useContext } from 'react';
import { FormContext } from '@ngt/forms';
import { InstitutionContext, PatientContext } from '@ngt/forms-trials';
import PatientInformation from './PatientInformation';

interface IContextPatientInformationProps {
}



const ContextPatientInformation: FunctionComponent<IContextPatientInformationProps> = () => {

    const { data: patient } = useContext(PatientContext);
    const { data: institution } = useContext(InstitutionContext);
    const { data: form } = useContext(FormContext);

    return (
        <PatientInformation
            patient={patient}
            institution={institution}
            form={form}
        />
    );
};

export default ContextPatientInformation;
