/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import React, { ChangeEvent, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CollapsibleTable, FormsContext, useSnackbar } from '@ngt/forms';
import { IPatient, useCountries, useInstitutions, usePatients } from '@ngt/forms-trials';
import { Box, Chip, Grid, TextField, Typography, useTheme, Theme, Autocomplete, Dialog, DialogTitle, DialogActions, Button, DialogContent, AlertTitle } from '@mui/material';
import { parse, stringify } from 'qs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

import * as Dtos from '../apis/dtos';
import { makeStyles } from '../styles/makeStyles';
import useAwaitingConsentPatientColumns from '../hooks/useAwaitingConsentPatientColumns';
import useCompletedConsentPatientColumns from '../hooks/useCompletedConsentPatientColumns';
import useRefusedConsentPatientColumns from '../hooks/useRefusedConsentPatientColumns';
import useLostToFollowUpConsentPatientColumns from '../hooks/useLostToFollowUpConsentPatientColumns';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IPatientsProps {

}

interface IPatientFilter {
    countryId?: number;
    institutionId?: number;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 0)
    },
    selectors: {
        padding: theme.spacing(0, 2)
    },
    requiresAttention: {
        color: theme.palette.warning.main,
        fontSize: '1.275rem'
    },
    cell: {
        //cursor: 'pointer',
        paddingLeft: `${theme.spacing(2)} !important`,
        paddingRight: `${theme.spacing(2)} !important`,

        '&:focus': {
            outline: 'none !important'
        },
        '&:focus-within': {
            outline: 'none !important'
        }
    },
    table: {
        padding: theme.spacing(2, 2)
    },
    header: {
        paddingLeft: `${theme.spacing(2)} !important`,
        paddingRight: `${theme.spacing(2)} !important`,

        '&:focus-within': {
            outline: 'none !important'
        }
    },
    dialogTitle: {
        paddingTop: `${theme.spacing(3)} !important`,
        fontSize: '1.075rem !important'
    }
}));

const xs = 12;
const sm = 12;
const md = 6;
const lg = 6;
const xl = 6;

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const Patients: React.FunctionComponent<IPatientsProps> = () => {

    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            title: {
                padding: theme.spacing(2, 2, 0)
            },
            selectors: {
                padding: theme.spacing(0, 2)
            },
            requiresAttention: {
                color: theme.palette.warning.main,
                fontSize: '1.275rem',
            },
            cell: {
                cursor: 'pointer',
                paddingLeft: `${theme.spacing(2)} !important`,
                paddingRight: `${theme.spacing(2)} !important`,

                '&:focus': {
                    outline: 'none !important'
                }
            },
            table: {
                padding: theme.spacing(2, 2)
            },
            header: {
                paddingLeft: `${theme.spacing(2)} !important`,
                paddingRight: `${theme.spacing(2)} !important`,
            }
        }
    }, [theme]);

    const navigate = useNavigate();

    const location = useLocation();

    const filter: IPatientFilter = useMemo(() => {
        const f = parse(location.search, { ignoreQueryPrefix: true }) as any;

        try {
            return {
                countryId: f.countryId ? parseInt(f.countryId, 10) : undefined,
                institutionId: f.institutionId ? parseInt(f.institutionId, 10) : undefined,
            }
        }
        catch {
            return {};
        }
    }, [location.search])

    const { data: countries, loading: countriesLoading } = useCountries();
    const { data: institutions, loading: institutionsLoading } = useInstitutions();
    const { data: patients, loading: patientsLoading, mutate: patientsMutate } = usePatients();

    const typedPatients = patients as Dtos.Patient[];

    const onPatientClick = useCallback((params: GridRowParams) => {
        navigate(`/patients/${(params.row as IPatient)?.studyNumber}/consent-form`);
    }, [navigate]);

    const headerClassName = useMemo(() => {
        return classes.header
    }, [classes.header]);

    const [patient, setPatient] = useState<IPatient | null>(null);

    const [patientUpdating, setPatientUpdating] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = useCallback(() => {
        if (modalOpen) {
            setPatient(null);
        }

        setModalOpen(state => !state);

    }, [setModalOpen, setPatient, modalOpen])

    const onLostToFollowUpClick = useCallback((patientId: number) => {
        setPatient(typedPatients?.find(patient => patient.id === patientId) ?? null);
        toggleModal();
    }, [typedPatients, setPatient, toggleModal, patients]);

    const formsContext = useContext(FormsContext);

    const client = formsContext.serviceStackClient;

    const { enqueueSnackbar } = useSnackbar();

    const onLostToFollowUpYesClick = React.useCallback(() => {
        setPatientUpdating(true);

        formsContext
            .serviceStackClient
            .post(new Dtos.ConsentFormPostSave({
                data: new Dtos.ConsentForm({
                    patientId: patient?.id,
                    repeat: 1,
                    reconsentStatus: Dtos.ReconsentStatus.LostToFollowUp
                })
            }))
            .then(response => {
                patientsMutate();
                setPatientUpdating(false);
                toggleModal();
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Status Updated
                        </AlertTitle>
                        The status was updated successfully.
                    </>,
                    { variant: 'success' }
                );
            })
            .catch((e) => {
                setPatientUpdating(false);
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Status Not Updated
                        </AlertTitle>
                        An error occurred while attempting to update the Status.
                    </>,
                    { variant: 'error-critical' }
                );
            })
    }, [setPatientUpdating, client, patientsMutate, toggleModal, enqueueSnackbar, patient, patientsMutate]);

    const awaitingConsentPatientColumns: GridColDef[] = useAwaitingConsentPatientColumns(
        institutions,
        headerClassName,
        onLostToFollowUpClick
    );

    const completedConsentPatientColumns: GridColDef[] = useCompletedConsentPatientColumns(institutions, headerClassName);
    const refusedConsentPatientColumns: GridColDef[] = useRefusedConsentPatientColumns(institutions, headerClassName);
    const lostToFollowUpConsentPatientColumns: GridColDef[] = useLostToFollowUpConsentPatientColumns(institutions, headerClassName);

    const patientsRouteFn = useCallback((search: string) => `/patients?${search}`, []);

    const selectedCountry = useMemo(() => {
        return countries?.find(x => x.id === filter.countryId) ?? null;
    }, [filter.countryId, countries]);

    const selectedInstitution = useMemo(() => {
        return institutions?.find(x => x.id === filter.institutionId) ?? null;
    }, [filter.institutionId, institutions]);

    const onCountryFilterChange = useCallback((event: ChangeEvent<{}>, value: { id?: number | undefined } | null) => {
        const search = stringify({
            ...filter,
            countryId: value?.id
        });

        navigate(patientsRouteFn(search));
    }, [filter, navigate, patientsRouteFn]);

    const onInstitutionFilterChange = useCallback((event: ChangeEvent<{}>, value: { id?: number | undefined } | null) => {
        const search = stringify({
            ...filter,
            institutionId: value?.id
        });

        navigate(patientsRouteFn(search));
    }, [filter, navigate, patientsRouteFn]);

    const filteredInstitutions = useMemo(() => {
        if (!filter.countryId) {
            return institutions;
        }

        return institutions?.filter(institution => {
            if (filter.countryId && institution?.countryId !== filter.countryId) {
                return false;
            }

            return true;
        }) ?? [];
    }, [institutions, filter]);

    const filteredPatients = useMemo(() => {
        if (!filter.countryId &&
            !filter.institutionId) {
            return typedPatients;
        }

        return typedPatients?.filter(patient => {


            if (filter.countryId) {
                const institution = institutions?.find(i => i.id === patient.institutionId);

                if (filter.countryId && institution?.countryId !== filter.countryId) {
                    return false;
                }
            }

            if (filter.institutionId && patient?.institutionId !== filter.institutionId) {
                return false;
            }

            return true;
        }) ?? [];
    }, [typedPatients, institutions, filter]);

    const awaitingConsentPatients = useMemo(() => {
        return filteredPatients?.filter(patient => (patient.reconsentStatus ?? Dtos.ReconsentStatus.Awaiting) == Dtos.ReconsentStatus.Awaiting);
    }, [filteredPatients]);

    const completedConsentPatients = useMemo(() => {
        return filteredPatients?.filter(patient => (patient.reconsentStatus ?? Dtos.ReconsentStatus.Awaiting) == Dtos.ReconsentStatus.Completed);
    }, [filteredPatients]);

    const refusedConsentPatients = useMemo(() => {
        return filteredPatients?.filter(patient => (patient.reconsentStatus ?? Dtos.ReconsentStatus.Awaiting) == Dtos.ReconsentStatus.Refused);
    }, [filteredPatients]);

    const lostToFollowUpConsentPatients = useMemo(() => {
        return filteredPatients?.filter(patient => (patient.reconsentStatus ?? Dtos.ReconsentStatus.Awaiting) == Dtos.ReconsentStatus.LostToFollowUp);
    }, [filteredPatients]);

    const getClassName = useCallback(() => {
        return classes.cell
    }, [classes.cell]);

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={toggleModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>Patient {patient?.studyNumber} was uncontactable and reconsent could not be obtained. Record as lost to follow up?</DialogTitle>
                <DialogActions>
                    <Button onClick={toggleModal} color="secondary">
                        No
                    </Button>
                    <Button onClick={onLostToFollowUpYesClick} color="primary" disabled={patientUpdating}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Typography variant="h1" sx={styles.title}>
                Participants
            </Typography>
            <Box sx={styles.selectors}>
                <Grid container spacing={2}>
                    {
                        !!countries?.length && (
                            <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                <Autocomplete
                                    id="countryId"
                                    options={countries ?? []}
                                    getOptionLabel={(option) => option.name}
                                    fullWidth
                                    loading={countriesLoading}
                                    popupIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
                                    renderInput={(params) => <TextField {...params} variant="standard" label="Country" />}
                                    onChange={onCountryFilterChange}
                                    value={selectedCountry}
                                />
                            </Grid>
                        )
                    }
                    {
                        !!institutions?.length && (
                            <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                <Autocomplete
                                    id="institutionId"
                                    options={filteredInstitutions ?? []}
                                    getOptionLabel={(option) => option.name}
                                    fullWidth
                                    loading={institutionsLoading}
                                    popupIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
                                    renderInput={(params) => <TextField {...params} variant="standard" label="Institution" />}
                                    onChange={onInstitutionFilterChange}
                                    value={selectedInstitution}
                                />
                            </Grid>
                        )
                    }
                </Grid>
            </Box>
            <Box sx={styles.table}>
                <CollapsibleTable
                    title="Awaiting Consent"
                    entityName="Participant"
                    rows={awaitingConsentPatients ?? []}
                    loading={countriesLoading ?? institutionsLoading ?? patientsLoading}
                    columns={awaitingConsentPatientColumns as any}
                    autoHeight
                    rowsPerPageOptions={undefined}
                    initialPageSize={undefined}
                    hideFooter={undefined}
                    disableColumnFilter
                    getCellClassName={getClassName}
                />
                <CollapsibleTable
                    title="Completed Consent"
                    entityName="Participant"
                    rows={completedConsentPatients ?? []}
                    loading={countriesLoading ?? institutionsLoading ?? patientsLoading}
                    columns={completedConsentPatientColumns as any}
                    autoHeight
                    rowsPerPageOptions={undefined}
                    initialPageSize={undefined}
                    hideFooter={undefined}
                    disableColumnFilter
                    getCellClassName={getClassName}
                />
                <CollapsibleTable
                    title="Refused Consent"
                    entityName="Participant"
                    rows={refusedConsentPatients ?? []}
                    loading={countriesLoading ?? institutionsLoading ?? patientsLoading}
                    columns={refusedConsentPatientColumns as any}
                    autoHeight
                    rowsPerPageOptions={undefined}
                    initialPageSize={undefined}
                    hideFooter={undefined}
                    disableColumnFilter
                    getCellClassName={getClassName}
                />
                <CollapsibleTable
                    title="Lost To Follow-Up Consent"
                    entityName="Participant"
                    rows={lostToFollowUpConsentPatients ?? []}
                    loading={countriesLoading ?? institutionsLoading ?? patientsLoading}
                    columns={lostToFollowUpConsentPatientColumns as any}
                    autoHeight
                    rowsPerPageOptions={undefined}
                    initialPageSize={undefined}
                    hideFooter={undefined}
                    disableColumnFilter
                    getCellClassName={getClassName}
                />
            </Box>
        </>
    );
}

/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default Patients;