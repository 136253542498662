import { FormsLayout } from '@ngt/forms';
import { FunctionComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Permission } from './apis/dtos';
import FormByPatientStudyNumberRoute, { IConsentFormMapping } from './components/route/FormByPatientStudyNumberRoute';
import Patients from './containers/Patients';
import ErrorPage, { ErrorTypeEnum } from './containers/error/ErrorPage';
import ConsentForm from './containers/form/ConsentForm';
import useHasPermission from './hooks/useHasPermission';
import { Box, CircularProgress } from '@mui/material';
import { useInstitutions } from '@ngt/forms-trials';

//import IFramePage from './containers/IFramePage';

const formMapping: Record<string, IConsentFormMapping> = {
    "consent-form": {
        components: <ConsentForm />
    }
}

const AppRoutes: FunctionComponent = () => {
    const { loading, error } = useInstitutions();

    if (loading) {
        return (
            <Box 
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: 'calc(100vh - 347.75px)',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <CircularProgress size='5rem'/>
            </Box>
        )
    }

    if (!loading && error) {
        return (
            <ErrorPage errorType={parseInt(error.errorCode) ?? ErrorTypeEnum.e403}/>
        )
    }

    return (
        <Routes>
            <Route
                path={`/patients/:patientStudyNumber/:formDefinitionCode/*`}
                element={
                    <FormByPatientStudyNumberRoute
                        resolveBeforeLoad
                        formMapping={formMapping}
                    />
                }
            />
            <Route 
                path="/patients"
                element={<Patients />}
            />
            <Route path="*" element={<Navigate replace to="/patients" />} />
        </Routes>
    )
}

const App: FunctionComponent = () => {
    return (
        <FormsLayout>
            <AppRoutes/>
        </FormsLayout>
    );
};

export default App;
