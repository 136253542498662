import { faBuilding, faHeartbeat, faUser } from '@fortawesome/pro-duotone-svg-icons';
import { Grid, Theme, useTheme } from '@mui/material';
import React, { FunctionComponent, useMemo } from 'react';
import { IInstitution, InformationCard, IPatient } from '@ngt/forms-trials';
import { IConsentForm, Patient } from '../../apis/dtos';
import { convertReconsentStatusToString } from '../../utilities/ReconsentStatus';
import { makeStyles } from '../../styles/makeStyles';
import { DateTime } from 'luxon';

interface IPatientInformationProps {
    patient?: IPatient | null;
    institution?: IInstitution | null;
    form?: IConsentForm | null;
}


const useStyles = makeStyles()((theme: Theme) => ({
    item: {
        display: 'flex',

        '& > *': {
            flex: '1 1 auto'
        }
    }
}));


const PatientInformation: FunctionComponent<IPatientInformationProps> = ({
    patient,
    institution,
    form
}) => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            item: {
                display: 'flex',

                '& > *': {
                    flex: '1 1 auto'
                }
            }
        }
    }, [theme]);

    const state = convertReconsentStatusToString(form?.reconsentStatus);

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                xs={12}
                md={4}
                sx={styles.item}
            >
                <InformationCard
                    color={theme.palette.primary.main}
                    icon={faUser}
                    title="Patient"
                    text={patient?.studyNumber}
                    caption={(patient as Patient)?.initials}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                sx={styles.item}
            >
                <InformationCard
                    color={theme.palette.secondary.main}
                    icon={faBuilding}
                    title="Institution"
                    text={institution?.code}
                    caption={institution?.name}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                sx={styles.item}
            >
                <InformationCard
                    color="#76c6d7"
                    icon={faHeartbeat}
                    title="Status"
                    text={state}
                    caption={form?.modifiedDate ? DateTime.fromISO(form?.modifiedDate).toFormat('dd/MM/yyyy') : DateTime.now().toFormat('dd/MM/yyyy')}
                />
            </Grid>
        </Grid>
    );
};

export default PatientInformation;
