/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 *  This file exports the react-tss make and with style fucntions for the provided theme
 * --------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * Import - External
 * --------------------------------------------------------------------------------
 */
/// <reference types="react" />
import { createMakeStyles } from "tss-react";
import { useTheme } from "@mui/material/styles";
export type { CSSInterpolation, CSSObject, Css, Cx, CxArg } from "tss-react/types";
export { createMakeStyles };

/*
 * --------------------------------------------------------------------------------
 * Functions
 * --------------------------------------------------------------------------------
 */

export const { makeStyles } = createMakeStyles({
    useTheme
});

