import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { BoundMutator, FormMetadata, Forms, FormsContext, IDataSingleResponse, IValidationResponse, IValidationResult } from '@ngt/forms';
import { IConsentForm } from '../apis/dtos';

// @ts-ignore: declared but not used 'url'
const fetcher = async <TConsentForm extends IConsentForm = IConsentForm>(url: string, forms: Forms, formMetadata: FormMetadata, patientStudyNumber: string, data?: TConsentForm, metadata?: Record<string, any>) => {
    if (!patientStudyNumber) {
        return await create<TConsentForm>(forms, formMetadata, data, metadata);
    }

    return await get<TConsentForm>(forms, formMetadata, patientStudyNumber)
}

const create = async <TConsentForm extends IConsentForm = IConsentForm>(forms: Forms, formMetadata: FormMetadata, data?: TConsentForm, metadata?: Record<string, any>) => {
    const requestType = forms.dtos[`${formMetadata.name}PostCreate`]

    const response: IDataSingleResponse<TConsentForm> = await forms.serviceStackClient.post(new requestType({ data, metadata }));

    return response;
}

const get = async <TConsentForm extends IConsentForm = IConsentForm>(forms: Forms, formMetadata: FormMetadata, patientStudyNumber: string) => {
    const requestType = forms.dtos[`${formMetadata.name}GetSingleByPatientStudyNumber`]

    const response: IDataSingleResponse<TConsentForm> = await forms.serviceStackClient.get(new requestType({ patientStudyNumber }));

    return response;
}

// @ts-ignore: declared but not used 'url'
const save = async <TConsentForm extends IConsentForm = IConsentForm>(forms: Forms, formMetadata: FormMetadata, data: TConsentForm, metadata: Record<string, any> | undefined) => {
    const requestType = forms.dtos[`${formMetadata.name}PostSave`]

    const response: IDataSingleResponse<TConsentForm> = await forms.serviceStackClient.post(new requestType({ data, metadata }));

    return response;
}

const validate = async <TConsentForm extends IConsentForm = IConsentForm, TValidationResult extends IValidationResult = IValidationResult>(forms: Forms, formMetadata: FormMetadata, data: TConsentForm, metadata: Record<string, any> | undefined) => {
    const requestType = forms.dtos[`${formMetadata.name}PostValidate`]

    const response: IValidationResponse<TValidationResult> = await forms.serviceStackClient.post(new requestType({ data, metadata }));

    return response;
}

const useFormByPatientStudyNumber = <TConsentForm extends IConsentForm = IConsentForm, TValidationResult extends IValidationResult = IValidationResult>(formDefinitionIdentifier?: number | string | null, patientStudyNumber?: string | null, createData?: TConsentForm, createMetadata?: Record<string, any>, configuration?: SWRConfiguration<IDataSingleResponse<TConsentForm>, IDataSingleResponse<TConsentForm>>) => {
    const forms = useContext(FormsContext);

    const formMetadata = useMemo(() => {
        if (typeof formDefinitionIdentifier === 'number') {
            return forms.formMetadata.find(fm => fm.formDefinitionId === formDefinitionIdentifier);
        }

        return forms.formMetadata.find(fm => fm.formDefinitionCode === formDefinitionIdentifier);
    }, [forms.formMetadata, formDefinitionIdentifier]);

    const cacheKey = useMemo(() => {
        if (!formMetadata) {
            return null;
        }

        if (!patientStudyNumber && !createData && !createMetadata) {
            return null;
        }

        if (!patientStudyNumber) {
            return [`${forms.baseRoute}/${formMetadata?.formDefinitionCode}/single/patient-study-number`, forms, formMetadata, null, createData, createMetadata]
        }

        return [`${forms.baseRoute}/${formMetadata?.formDefinitionCode}/single/patient-study-number`, forms, formMetadata, patientStudyNumber, null, null]
    }, [forms, patientStudyNumber, formMetadata, createData, createMetadata])

    const { data, error } = useSWR<IDataSingleResponse<TConsentForm>, IDataSingleResponse<TConsentForm>>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<IDataSingleResponse<TConsentForm>> = useCallback((newData, shouldRevalidate) => {
        return mutate(cacheKey, newData, shouldRevalidate);
    }, [cacheKey]);

    const boundSave = useCallback(async (saveData: TConsentForm, metadata?: Record<string, any>, shouldRevalidate?: boolean) => {
        if (formMetadata) {

            const response = await save<TConsentForm>(forms, formMetadata, saveData, metadata);

            if (cacheKey) {
                // !!cacheKey[3] = has an id.
                const revalidate = shouldRevalidate ?? !!cacheKey[3];

                const updated = await boundMutate(response, revalidate);

                return updated?.data;
            }

            return response?.data;
        }

        return null;
    }, [boundMutate, forms, formMetadata, cacheKey]);

    const boundValidate = useCallback(async (validateData: TConsentForm, metadata?: Record<string, any>) => {
        if (formMetadata) {
            const response = await validate<TConsentForm, TValidationResult>(forms, formMetadata, validateData, metadata);

            return response?.validationResult;
        }

        return null;
    }, [forms, formMetadata]);


    const result = useMemo(() => {
        return {
            data: data?.data as TConsentForm | undefined | null,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate,
            save: boundSave,
            validate: boundValidate
        };
    }, [boundMutate, data, error, boundValidate, boundSave])

    return result;
}

export default useFormByPatientStudyNumber;