/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use pre-registered patient columns.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import { Button, Chip, Dialog, DialogTitle, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { IInstitution } from '@ngt/forms-trials';
import * as React from 'react';
import { Link } from 'react-router-dom';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useAwaitingConsentPatientColumns = (institutions: IInstitution[] | undefined, headerClassName: string, onLostToFollowUpClick: (id: number) => void): GridColDef[] => {
    const columns = React.useMemo(() => {
        return [
            {
                field: 'studyNumber',
                headerName: 'Paticipant Number',
                width: 250,
                headerClassName: headerClassName
            },
            {
                field: 'institutionCode',
                headerName: 'Institution',
                valueGetter: (params: any) => institutions?.find(i => i.code === params.row.institutionCode)?.code,
                renderCell: (params: any) => {
                    if (!params.value) {
                        return <div />;
                    }

                    return (
                        <Chip
                            label={<Typography sx={{ fontSize: '0.8rem' }}>{params.value}</Typography>}
                            size="small"
                            color="secondary"
                        />
                    )
                },
                width: 200,
                headerClassName: headerClassName
            },
            {
                field: 'institutionName',
                headerName: 'Institution Name',
                valueGetter: (params: any) => institutions?.find(i => i.code === params.row.institutionCode)?.name,
                flex: 1,
                headerClassName: headerClassName
            },
            {
                field: 'id',
                headerName: '',
                renderCell: (params: any) => {
                    return (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                component={Link}
                                to={`/patients/${params?.row.studyNumber}/consent-form/`}
                                sx={{
                                    marginRight: '1rem',
                                }}
                            >
                                Consent Form
                            </Button>  
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => onLostToFollowUpClick(params?.row.id)}
                            >
                                Not Contactable
                            </Button>  


                        </>
                    );
                },
                headerClassName: headerClassName,
                width: 400,
            },
        ];
    }, [institutions, headerClassName, onLostToFollowUpClick]);

    return columns;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useAwaitingConsentPatientColumns;