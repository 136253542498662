/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { useContext, useMemo } from 'react';
import { faCircle, faClipboardList, faMinusCircle, faQuestionCircle, faTimesCircle, faUserCircle } from '@fortawesome/pro-duotone-svg-icons';
import { ReconsentStatus } from '../../apis/dtos';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { DateTime } from 'luxon';
import { FormContext, FormDefinitionContext, FormTypeContext } from '@ngt/forms';
import { Breadcrumbs, IBreadcrumb, PatientContext } from '@ngt/forms-trials';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the PatientBreadcrumbs component.
 */
export interface IContextFormBreadcrumbsProps {
}


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const ContextFormBreadcrumbs: React.FunctionComponent<IContextFormBreadcrumbsProps> = () => {
    const { data: patient } = useContext(PatientContext);
    const { data: formDefinition } = useContext(FormDefinitionContext);
    const { data: formType } = useContext(FormTypeContext);
    const { data: form } = useContext(FormContext);

    const breadcrumbs = useMemo(() => {
        const b: IBreadcrumb[] = [];

        if (patient) {
            b.push({
                icon: faUserCircle,
                label: patient.studyNumber,
                to: `/patients/${patient.studyNumber}/consent-form`
            });
        }
        else {
            b.push({
                icon: faUserCircle,
                label: 'Unknown',
                to: '#'
            });
        }

        if (patient  && formDefinition && form) {
            b.push({
                icon: faClipboardList,
                label: <>{formDefinition?.name}</>,
                to: `/patients/${patient.studyNumber}/consent-form`
            });
        }
        else {
            b.push({
                icon: faClipboardList,
                label: 'Unknown',
                to: '#'
            });
        }

        return b;
    }, [patient, formDefinition, form, formType?.name])

    return (
        <Breadcrumbs breadcrumbs={breadcrumbs} />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ContextFormBreadcrumbs;