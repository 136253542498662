/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import React, { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


// import { default as FormBase, IFormProps as IFormBaseProps } from '@ngt/forms-core';
import { IFormSubmitValidationFailed, IFormSubmitFailed, IFormSubmit, IFormContext, useScopedField, ValidateOn, IFormAllowSubmit } from '@ngt/forms-core';
import { ContextForm as BaseContextForm, IContextFormProps as IBaseContextFormProps, IValidationError, IValidationResult, SubmitButton, useSnackbar, titleCase, FormDefinitionContext, ValidationErrorType, FormContext, FieldProvider, GroupedErrorDisplay } from '@ngt/forms';
import { IConsentForm, ReconsentStatus } from '../../apis/dtos';
import { AlertTitle } from '@mui/lab';
import pluralize from 'pluralize';
import { Button, SxProps, Theme, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PatientContext } from '@ngt/forms-trials';
import { makeStyles } from '../../styles/makeStyles';
import { Box } from '@mui/material';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

///**
// * This interface defines the properties for the Form component.
// */
//export interface IFormProps<TData extends Record<string, any> = Record<string, any>> extends UseFormOptions<TData> {
//    onSubmit: SubmitHandler<TData>
//    children?: React.ReactNode;
//}

/**
 * This interface defines the properties for the Form component.
 */
export interface IContextFormProps<TData extends IConsentForm = IConsentForm, TValidationResult extends IValidationResult = IValidationResult> extends IBaseContextFormProps<TData, TValidationResult> {
}
/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles()((theme: Theme) => ({
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    margin: {
        marginLeft: theme.spacing(2)
    }
}));

const subscription = { value: true };

interface ICompleteButton {
    entityName: string;
}

const CompleteButton: FunctionComponent<ICompleteButton> = ({
    entityName,
}) => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => {
        return {
            buttonGroup: {
                padding: theme.spacing(2),
                textAlign: 'right'
            },
            margin: {
                marginLeft: theme.spacing(2)
            }
        }
    }, [theme]);

    const formContext = useContext(FormContext);

    const form = (formContext as any).data;

    const update = (formContext as any).update;

    const { enqueueSnackbar } = useSnackbar();

    const onSubmitClick = useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<IConsentForm, IValidationError>) => {
        formActions?.setFieldValue('reconsentStatus', ReconsentStatus.Awaiting, false, false, false, false);
    }, []);

    const navigate = useNavigate();

    const onCancelClick = useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<IConsentForm, IValidationError>) => {
        navigate(`/patients`);
    }, [navigate]);

    const { state: { value } } = useScopedField<ReconsentStatus>('reconsentStatus', subscription, false);

    // TO DO: hide / disable buttons
    //const { data: [canCompleteMedicalReviewForm] } = useContextPermissions([MedicalReviewPermission.CompleteMedicalReviewForm, MedicalReviewPermission.UpdateMedicalReview]);

    //if (value === ReconsentStatus.Completed) {
    //    return null;
    //}

    //if (!canUpdateMedicalReviewForm &&
    //    !(canCompleteMedicalReviewForm && value !== ReconsentStatus.Complete)) {
    //    return null;
    //}

    return (
        <>
            <Box sx={styles.buttonGroup}>
                <SubmitButton onClick={onSubmitClick} sx={styles.margin} variant="contained" color="primary">
                    Submit
                </SubmitButton>
                <SubmitButton onClick={onCancelClick} sx={styles.margin} variant="contained" color="primary">
                    Cancel
                </SubmitButton>
            </Box>
        </>
    )
}

const ContextForm = <TData extends IConsentForm = IConsentForm, TValidationResult extends IValidationResult = IValidationResult>({
    entity,
    onSubmitFailed,
    onSubmitValidationFailed,
    children,
    onAfterSubmit,

    ...formProps
}: IContextFormProps<TData, TValidationResult>) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const { data: formDefinition } = useContext(FormDefinitionContext);
    const { data: form } = useContext(FormContext);
    const { data: patient } = useContext(PatientContext);
    const typedForm: TData = form as any;

    const entityName = useMemo(() => {
        return entity ?? formDefinition?.name ?? 'Unknown';
    }, [formDefinition, entity]);

    const onAfterSubmitToUse: IFormSubmit<TData, IValidationError> = useCallback(async (formState, formActions) => {
        if (onAfterSubmit) {
            return onAfterSubmit(formState, formActions)
        }

        navigate(`/patients`);
    }, [patient, onAfterSubmit, navigate])

    const onSubmitValidationFailure: IFormSubmitValidationFailed<TData, IValidationError> = useCallback(async (formState, formActions, validationError: boolean) => {
        formActions?.setFieldValue('reconsentStatus', ReconsentStatus.Awaiting, false, false, false, false);

        if (onSubmitValidationFailed) {
            return onSubmitValidationFailed(formState, formActions, validationError);
        }

        const entityTitleCase = titleCase(entityName);
        const entityLowerCase = entityName?.toLowerCase();

        const { errors } = formState;

        if (validationError) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {entityTitleCase} Not Saved
                    </AlertTitle>
                    An error occurred while attempting to validate {entityLowerCase}.
                </>,
                { variant: 'error-critical' }
            );
        }
        else {
            const criticalErrors = Object
                .keys(errors)
                .reduce((array: IValidationError[], key: string) => {
                    const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                        if (e.type !== ValidationErrorType.Warning) {
                            return [...propertyArray, e]
                        }

                        return propertyArray;
                    }, [])

                    return [...array, ...propertyErrors]
                }, []);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {entityTitleCase} Not Saved
                    </AlertTitle>
                    Please correct the {criticalErrors.length} blocking {pluralize('error', criticalErrors.length)} and submit {entityLowerCase} again.
                </>,
                { variant: 'error-critical' }
            );
        }
    }, [enqueueSnackbar, entityName, onSubmitValidationFailed, typedForm]);

    const onFormSubmitFailure: IFormSubmitFailed<TData, IValidationError> = useCallback(async (formState, formActions) => {
        formActions?.setFieldValue('reconsentStatus', ReconsentStatus.Awaiting, false, false, false, false);

        if (onSubmitFailed) {
            return onSubmitFailed(formState, formActions, null);
        }

        formActions.reset(true);

        const entityTitleCase = titleCase(entityName);
        const entityLowerCase = entityName?.toLowerCase();

        enqueueSnackbar(
            <>
                <AlertTitle>
                    {entityTitleCase} Not Saved
                </AlertTitle>
                An error occurred while attempting to save {entityLowerCase}.
            </>,
            { variant: 'error-critical' }
        );
    }, [onSubmitFailed, entityName, typedForm, enqueueSnackbar]);

    const allowSubmitToUse: IFormAllowSubmit<TData, IValidationError> = useCallback(async (formState, formActions) => {
        const { errors } = formState;

        if (!errors) {
            return true;
        }

        return !Object.keys(errors).some(key => errors[key] && errors[key].some(e => (e.type ?? ValidationErrorType.Critical) > ValidationErrorType.Normal));
    }, []);

    return (
        <BaseContextForm
            {...formProps}
            entity={entity}
            onSubmitFailed={onFormSubmitFailure}
            onSubmitValidationFailed={onSubmitValidationFailure}
            onAfterSubmit={onAfterSubmitToUse}
            validateOn={ValidateOn.onChange}
        >
            {children}
            <FieldProvider name="reconsentStatus" />
            <GroupedErrorDisplay />
            <CompleteButton entityName={entityName} />
        </BaseContextForm>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ContextForm;