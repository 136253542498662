/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected form.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import React, { useMemo } from 'react';
import {
    RouteLoading,
    useFormTypeById,
    useFormDefinitionByCode,
    useLookupsByFormDefinitionId,
    FormDefinitionContext,
    FormTypeContext,
    FormContext,
    LookupsContext,
    FormDefinitionSingleResponse,
    LookupCollectionResponse,
    IDataSingleResponse,
    FormTypeSingleResponse
} from '@ngt/forms';
import { SWRConfiguration } from 'swr/dist/types';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
    CoordinatingGroupContext,
    CoordinatingGroupSingleResponse,
    CountryContext,
    CountrySingleResponse,
    InstitutionContext,
    InstitutionSingleResponse,
    MasterGroupContext,
    MasterGroupSingleResponse,
    PatientContext,
    PatientSingleResponse,
    useCoordinatingGroupById,
    useCountryById,
    useInstitutionById,
    useMasterGroupById,
    usePatientByStudyNumber
} from '@ngt/forms-trials';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { IConsentForm } from '../../apis/dtos';
import useFormByPatientStudyNumber from '../../hooks/useFormByPatientStudyNumber';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IConsentFormMapping {
    components?: React.ReactNode;
    createData?: IConsentForm;
    createMetadata?: Record<string, any>;
}

export interface IFormByPatientStudyNumberResolverProps {
    formDefinitionCode: string | null;
    patientStudyNumber: string | null;
    resolveBeforeLoad?: boolean | null;
    formMapping?: Record<string, IConsentFormMapping>;

    patientConfiguration?: Partial<SWRConfiguration<PatientSingleResponse, PatientSingleResponse>>;
    institutionConfiguration?: Partial<SWRConfiguration<InstitutionSingleResponse, InstitutionSingleResponse>>;
    countryConfiguration?: Partial<SWRConfiguration<CountrySingleResponse, CountrySingleResponse>>;
    coordinatingGroupConfiguration?: Partial<SWRConfiguration<CoordinatingGroupSingleResponse, CoordinatingGroupSingleResponse>>;
    masterGroupConfiguration?: Partial<SWRConfiguration<MasterGroupSingleResponse, MasterGroupSingleResponse>>;
    formDefinitionConfiguration?: Partial<SWRConfiguration<FormDefinitionSingleResponse, FormDefinitionSingleResponse>>;
    lookupConfiguration?: Partial<SWRConfiguration<LookupCollectionResponse, LookupCollectionResponse>>;
    formTypeConfiguration?: Partial<SWRConfiguration<FormTypeSingleResponse, FormTypeSingleResponse>>;
    formConfiguration?: Partial<SWRConfiguration<IDataSingleResponse<IConsentForm>, IDataSingleResponse<IConsentForm>>>;

    children?: React.ReactNode
}
/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const FormByPatientStudyNumberResolver: React.FunctionComponent<IFormByPatientStudyNumberResolverProps> = ({
    formDefinitionCode,
    patientStudyNumber,
    resolveBeforeLoad,
    formMapping,
    children,

    patientConfiguration,
    institutionConfiguration,
    countryConfiguration,
    coordinatingGroupConfiguration,
    masterGroupConfiguration,
    formDefinitionConfiguration,
    formTypeConfiguration,
    formConfiguration,
    lookupConfiguration,
}) => {
    const formDefinitionContext = useFormDefinitionByCode(formDefinitionCode ? formDefinitionCode : undefined, formDefinitionConfiguration);

    const formDefCode = useMemo(() => formDefinitionContext?.data?.code ?? '', [formDefinitionContext?.data?.code]);

    const currentFormMapping = useMemo(() => {
        return formMapping?.[formDefCode]
    }, [formDefCode, formMapping]);

    const patientContext = usePatientByStudyNumber(patientStudyNumber, undefined, undefined, patientConfiguration);

    const institutionContext = useInstitutionById(patientContext?.data?.institutionId, undefined, undefined, institutionConfiguration);

    const countryContext = useCountryById(institutionContext?.data?.countryId, undefined, undefined, countryConfiguration);

    const coordinatingGroupContext = useCoordinatingGroupById(institutionContext?.data?.coordinatingGroupId, undefined, undefined, coordinatingGroupConfiguration);

    const masterGroupContext = useMasterGroupById(institutionContext?.data?.masterGroupId, undefined, undefined, masterGroupConfiguration);

    const formTypeContext = useFormTypeById(formDefinitionContext?.data?.type, formTypeConfiguration);

    const lookupsContext = useLookupsByFormDefinitionId(formDefinitionContext?.data?.id, lookupConfiguration);

    const formContext = useFormByPatientStudyNumber(formDefinitionCode, patientStudyNumber, currentFormMapping?.createData, currentFormMapping?.createMetadata, formConfiguration);

    let conditionalContexts: React.ReactNode =
        !!formMapping && formDefCode && !!formMapping[formDefCode]?.components ?
            formMapping[formDefCode]?.components :
            children;

    const loading =
        (formDefinitionCode && formDefinitionContext?.loading) ||
        (patientStudyNumber && patientContext?.loading) ||
        (patientContext?.data?.id && institutionContext?.loading) ||
        (institutionContext?.data?.countryId && countryContext?.loading) ||
        (institutionContext?.data?.coordinatingGroupId && coordinatingGroupContext?.loading) ||
        (institutionContext?.data?.masterGroupId && masterGroupContext?.loading) ||
        (formDefinitionCode && patientStudyNumber && formContext?.loading) ||
        (formDefinitionContext?.data?.id && lookupsContext?.loading) ||
        (formDefinitionContext?.data?.type && formTypeContext?.loading);

    const hasError = !!(
        !patientStudyNumber ||
        !formDefinitionCode ||
        formDefinitionContext?.error ||
        patientContext?.error ||
        institutionContext?.error ||
        countryContext?.error ||
        coordinatingGroupContext?.error ||
        masterGroupContext?.error ||
        formTypeContext?.error ||
        formContext?.error ||
        lookupsContext?.error
    );

    if (resolveBeforeLoad && hasError) {
        return (
            <Routes>
                <Route path="*" element={<Navigate to="/error" />} />
            </Routes>
        )
    }

    return (
        <MasterGroupContext.Provider value={masterGroupContext}>
            <CoordinatingGroupContext.Provider value={coordinatingGroupContext}>
                <CountryContext.Provider value={countryContext}>
                    <InstitutionContext.Provider value={institutionContext}>
                        <PatientContext.Provider value={patientContext}>
                            <FormTypeContext.Provider value={formTypeContext}>
                                <FormDefinitionContext.Provider value={formDefinitionContext}>
                                    <FormContext.Provider value={formContext}>
                                        <LookupsContext.Provider value={lookupsContext}>
                                            {   
                                                !resolveBeforeLoad || !loading ?
                                                    conditionalContexts : 
                                                    <RouteLoading />
                                            }
                                        </LookupsContext.Provider>
                                    </FormContext.Provider>
                                </FormDefinitionContext.Provider>
                            </FormTypeContext.Provider>
                        </PatientContext.Provider>
                    </InstitutionContext.Provider>
                </CountryContext.Provider>
            </CoordinatingGroupContext.Provider>
        </MasterGroupContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormByPatientStudyNumberResolver;
