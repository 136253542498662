/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected form.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import React, { useContext } from 'react';
import { RouteProps, useParams } from 'react-router-dom';
import {
    FormDefinitionSingleResponse,
    LookupCollectionResponse,
    IDataSingleResponse,
    FormTypeSingleResponse,
    FormsContext
} from '@ngt/forms';
import { SWRConfiguration } from 'swr/dist/types';
import {
    CoordinatingGroupSingleResponse,
    CountrySingleResponse,
    InstitutionSingleResponse,
    MasterGroupSingleResponse,
    PatientSingleResponse,
    TrialsExtensionContext,
} from '@ngt/forms-trials';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { IConsentForm } from '../../apis/dtos';
import FormByPatientStudyNumberResolver from '../resolver/FormByPatientStudyNumberResolver';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IConsentFormMapping {
    components?: React.ReactNode;
    createData?: IConsentForm;
    createMetadata?: Record<string, any>;
}

export interface IFormByPatientStudyNumberRouteProps extends RouteProps {
    formDefinitionCode?: string | null;
    patientStudyNumber?: string | null;
    resolveBeforeLoad?: boolean | null;
    formMapping?: Record<string, IConsentFormMapping>;

    patientConfiguration?: Partial<SWRConfiguration<PatientSingleResponse, PatientSingleResponse>>;
    institutionConfiguration?: Partial<SWRConfiguration<InstitutionSingleResponse, InstitutionSingleResponse>>;
    countryConfiguration?: Partial<SWRConfiguration<CountrySingleResponse, CountrySingleResponse>>;
    coordinatingGroupConfiguration?: Partial<SWRConfiguration<CoordinatingGroupSingleResponse, CoordinatingGroupSingleResponse>>;
    masterGroupConfiguration?: Partial<SWRConfiguration<MasterGroupSingleResponse, MasterGroupSingleResponse>>;
    formDefinitionConfiguration?: Partial<SWRConfiguration<FormDefinitionSingleResponse, FormDefinitionSingleResponse>>;
    lookupConfiguration?: Partial<SWRConfiguration<LookupCollectionResponse, LookupCollectionResponse>>;
    formTypeConfiguration?: Partial<SWRConfiguration<FormTypeSingleResponse, FormTypeSingleResponse>>;
    formConfiguration?: Partial<SWRConfiguration<IDataSingleResponse<IConsentForm>, IDataSingleResponse<IConsentForm>>>;

    children?: React.ReactNode
}
/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const FormByPatientStudyNumberRoute: React.FunctionComponent<IFormByPatientStudyNumberRouteProps> = ({
    formDefinitionCode,
    patientStudyNumber,
    resolveBeforeLoad,
    formMapping,
    children,

    patientConfiguration,
    institutionConfiguration,
    countryConfiguration,
    coordinatingGroupConfiguration,
    masterGroupConfiguration,
    formDefinitionConfiguration,
    formTypeConfiguration,
    formConfiguration,
    lookupConfiguration,

    ...routeProps
}) => {

    const params = useParams<Record<string, string>>();
    const formsContext = useContext(FormsContext);
    const trialsExtensionContext = useContext(TrialsExtensionContext);
    const paramFormDefCode = params[formsContext.routeParameters.formDefinitionCode];
    const paramPatientStudyNumber = params[trialsExtensionContext.routeParameters.patientStudyNumber];

    return (
        <FormByPatientStudyNumberResolver
            formDefinitionCode={paramFormDefCode ?? formDefinitionCode ?? null}
            patientStudyNumber={paramPatientStudyNumber ?? patientStudyNumber ?? null}
            resolveBeforeLoad={resolveBeforeLoad}
            formMapping={formMapping}
            coordinatingGroupConfiguration={coordinatingGroupConfiguration}
            countryConfiguration={countryConfiguration}
            formConfiguration={formConfiguration}
            formDefinitionConfiguration={formDefinitionConfiguration}
            formTypeConfiguration={formTypeConfiguration}
            institutionConfiguration={institutionConfiguration}
            lookupConfiguration={lookupConfiguration}
            masterGroupConfiguration={masterGroupConfiguration}
            patientConfiguration={patientConfiguration}
        >
            {children}
        </FormByPatientStudyNumberResolver>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormByPatientStudyNumberRoute;
