import React from 'react';
import ReactDOM from 'react-dom';

//import reportWebVitals from './reportWebVitals';
import { FormsProvider } from '@ngt/forms';
import forms from './forms';
import App from './App';
import { CssBaseline } from '@mui/material';

ReactDOM.render(
    <React.StrictMode>
        <FormsProvider
            value={forms}
        >
            <CssBaseline />
            <App />
        </FormsProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
