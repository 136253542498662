import { Box, Theme, Typography } from '@mui/material';
import { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { IGroupedFieldStyleProps, GroupedFields, Text, GroupedField, DatePicker, Select, RadioGroup, IConditionProps, Condition, IConditionParameters, Numeric, IValidationError } from '@ngt/forms';
import { ConsentForm as ConsentFormDto, LookupYesNoType, ReconsentStatus } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode, IFormSubmit, useScopedField } from '@ngt/forms-core';
import { BasicAlert, InstitutionContext, PatientContext } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import ContextPatientInformation from '../../components/patient/ContextPatientInformation';
import ContextFormBreadcrumbs from '../../components/breadcrumbs/ContextFormBreadcrumbs';
import ContextForm from '../../components/form/ContextForm';
import ContextFormTitle from '../../components/form/ContextFormTitle';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import { NumberFormatValues } from 'react-number-format';

interface IConsentFormProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    subheader: {
        padding: theme.spacing(0, 2, 2)
    },
    label: {
        paddingTop: `0 !important`
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 4,
    lg: 3,
    xl: 3
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 8,
    lg: 9,
    xl: 9
}

const groupStyleProps: IGroupedFieldStyleProps = {
        labelColumn: defaultLabelColumnSizes,
        inputColumn: defaultInputColumnSizes
};

const FOUR_DIGIT_POSITIVE_INTEGER = {
    decimalScale: 0,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 10000) {
            return true;
        }

        return false;
    }
}

const conditionSubscription = { value: true };

const isReconsentNotNo = ({ formState: { value } = {} }) => value?.consentFollowUp !== LookupYesNoType.No;
const isConsentDataRegistryYes = ({ formState: { value } = {} }) => value?.consentDataRegistry === LookupYesNoType.Yes;

const ConsentForm: FunctionComponent<IConsentFormProps> = () => {
    const { classes } = useStyles();

    const styles = useMemo(() => {
        return {
            input: {
                marginTop: -1
            },
            radioGroup: {
                flexDirection: 'row',
                height: '2rem',

                '& label': {
                    marginRight: '5rem'
                }
            },
            radio: {
                margin: '-1rem 0'
            }
        }
    }, []);

    const getGroupStyleProps = useCallback((isGrouped?: boolean) => {

        var styleProps = Object.assign({}, groupStyleProps);

        styleProps.labelColumnClassName = classes.label;
        styleProps.inputColumnSx = styles.input;

        if (!isGrouped) {
            styleProps.paddingTop = 3;
            styleProps.paddingBottom = 3
        }

        return styleProps;

    }, [groupStyleProps, classes, styles]);

    return (
        <>
            <ContextFormBreadcrumbs />
            <Box className={classes.patientInformation}>
                <ContextPatientInformation />
            </Box>
            <Box className={classes.titleContainer}>
                <Typography variant="h1">
                    <ContextFormTitle />
                </Typography>
            </Box>
            <ContextForm>
                <GroupedField
                    name="consentFollowUp"
                    component={RadioGroup}
                    GroupStyleProps={getGroupStyleProps()}
                    RadioProps={{ sx: styles.radio }}
                    sx={styles.radioGroup}
                />
                <Condition
                    type={ConsentFormDto}
                    condition={isReconsentNotNo}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Enable}
                >
                    <GroupedField
                        name="consentDataRegistry"
                        component={RadioGroup}
                        GroupStyleProps={getGroupStyleProps()}
                        RadioProps={{ sx: styles.radio }}
                        sx={styles.radioGroup}
                    />
                </Condition>
                <Condition
                    type={ConsentFormDto}
                    condition={isConsentDataRegistryYes}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedField
                        name="firstName"
                        component={Text}
                        variant="standard"
                        GroupStyleProps={getGroupStyleProps()}
                        fullWidth
                    />
                    <GroupedField
                        name="surname"
                        component={Text}
                        variant="standard"
                        GroupStyleProps={getGroupStyleProps()}
                        fullWidth
                    />
                    <GroupedField
                        name="dateOfBirth"
                        component={DatePicker}
                        renderProps={{ variant: "standard", fullWidth: true }}
                        GroupStyleProps={getGroupStyleProps()}
                        disableFuture={true}
                        OpenPickerButtonProps={{
                            style: { marginRight: 1 }
                        }}
                    />
                    <GroupedField
                        name="biologicalSex"
                        component={Select}
                        variant="standard"
                        GroupStyleProps={getGroupStyleProps()}
                        fullWidth
                    />
                    <GroupedFields
                        paddingTop={2}
                        paddingBottom={2}
                    >
                        <Typography variant="h2" className={classes.subheader}>
                            Residential/Home Address
                        </Typography>
                        <GroupedField
                            name="streetNumber"
                            component={Text}
                            variant="standard"
                            GroupStyleProps={getGroupStyleProps(true)}
                            fullWidth
                        />
                        <GroupedField
                            name="streetName"
                            component={Text}
                            variant="standard"
                            GroupStyleProps={getGroupStyleProps(true)}
                            fullWidth
                        />
                        <GroupedField
                            name="suburbRegion"
                            component={Text}
                            variant="standard"
                            GroupStyleProps={getGroupStyleProps(true)}
                            fullWidth
                        />
                        <GroupedField
                            name="state"
                            component={Text}
                            variant="standard"
                            GroupStyleProps={getGroupStyleProps(true)}
                            fullWidth
                        />
                        <GroupedField
                            name="postcode"
                            component={Numeric}
                            variant="standard"
                            GroupStyleProps={getGroupStyleProps(true)}
                            numberFormat={FOUR_DIGIT_POSITIVE_INTEGER}
                            fullWidth
                        />
                    </GroupedFields>
                </Condition>
                <GroupedField
                    name="reconsentDate"
                    component={DatePicker}
                    renderProps={{ variant: "standard", fullWidth: true }}
                    GroupStyleProps={getGroupStyleProps()}
                    disableFuture
                    OpenPickerButtonProps={{
                        style: { marginRight: 1 }
                    }}
                />
            </ContextForm>
        </>
    );
};

export default ConsentForm;
