import { ReconsentStatus } from "../apis/dtos";

export const convertReconsentStatusToString = (status?: ReconsentStatus) => {
    if (!status) {
        return 'No Reviews';
    }

    switch (status) {
        case ReconsentStatus.Awaiting:
            return 'Awaiting';
        case ReconsentStatus.Completed:
            return 'Completed';
        case ReconsentStatus.Refused:
            return 'Refused';
        case ReconsentStatus.LostToFollowUp:
            return 'Lost To Follow-Up';
        default:
            return 'Unknown';
    }
}