/* eslint-disable spaced-comment */
/// <reference types="react-scripts" />
import bctLogo from './assets/img/bct.png';
import { Forms } from '@ngt/forms';
import { responsiveFontSizes } from '@mui/material';
import { mutate, SWRConfig } from 'swr';
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';
import Footer from './components/layout/Footer';
import * as Dtos from './apis/dtos';
import { TrialsExtension } from '@ngt/forms-trials';

declare let global: any;

const cache = SWRConfig.default.cache;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const user = global.AUTH as Dtos.UserSingleResponse;
const formMetadata = global.FORM_METADATA as Dtos.GetFormMetadataResponse;

if (!user || !user.user) {
    if (global.location.search) {
        global.location.href = '/auth/openiddict' + global.location.search;
    } else {
        global.location.href = '/auth/openiddict';
    }
}

const primaryColor = '#562c85';
const secondaryColor = '#e8078c';

const forms = new Forms({
    initialAuthentication: user,
    dtos: Dtos,
    baseRoute: '/patients',
    extensions: [
        new TrialsExtension({
            organisationLogo: bctLogo,
            organisationName: "Breast Cancer Trials",
            trialName: "POSNOC",
            menuItems: async (auth, forms) => {
                const menuItems = [
                    {
                        url: '/patients',
                        label: 'Participants',
                        external: false,
                        newTab: false,
                        icon: faUser
                    }
                ]

                return menuItems;
            },
            footer: <Footer />,
            routeParameters: {
                patientStudyNumber: 'patientStudyNumber'
            }
        }),
    ],
    formMetadata: formMetadata?.formMetadata,
    themeOptions: {
        beforeThemeCreated: themeOptions => {
            return {
                ...themeOptions,
                palette: {
                    ...themeOptions.palette,
                    primary: {
                        main: primaryColor
                    },
                    secondary: {
                        main: secondaryColor
                    }
                },
                typography: {
                    ...themeOptions.typography,
                    h1: {
                        ...(themeOptions?.typography as any)?.h1,
                        color: secondaryColor
                    },
                    h2: {
                        ...(themeOptions?.typography as any)?.h2,
                        color: primaryColor
                    },
                    h3: {
                        ...(themeOptions?.typography as any)?.h3,
                        color: secondaryColor
                    },
                    h4: {
                        ...(themeOptions?.typography as any)?.h4,
                        color: primaryColor
                    },
                    h5: {
                        ...(themeOptions?.typography as any)?.h5,
                        color: secondaryColor
                    },
                    h6: {
                        ...(themeOptions?.typography as any)?.h6,
                        color: primaryColor
                    }
                }
            };
        },
        afterThemeCreated: theme => {
            return responsiveFontSizes(theme as any) as any;
        }
    }
});

forms.serviceStackClient.responseFilter = (res: Response) => {

     const userKey = [`${forms.baseRoute}/user`, forms];

     const userResponse: Dtos.UserSingleResponse | undefined = cache.get(userKey);

     if (res.headers.has('openiddict-user-status') &&
         res.headers.get('openiddict-user-status') === 'unauthenticated' &&
         userResponse) {
         mutate(userKey, userResponse, true);
     }
     else if (res.headers.has('openiddict-user-update') &&
         res.headers.get('openiddict-user-update') === 'true') {
         mutate(userKey, userResponse, true);
     }
 }

forms.serviceStackClient.exceptionFilter = (res: Response, error: any) => {
    if (res.status === 401) {
        global.location.href = `/auth/openiddict?redirect=${encodeURIComponent(global.location.pathname + global.location.search)}`;
    }
};

export default forms;